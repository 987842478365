/* global $, App */

import {
  postInit
} from "./platin";
import * as d3 from "d3";

let subscription, dialogTimestamp,
  exportButton,
  channelOutput,
  progressBar,
  downloadButton,
  eta,
  etaString;
const format = d3.format(",.0%");

/* this contains the DOM node of the dialog */
function initDialog() {
  const dialog = this;
  exportButton = $("#export-button", dialog),
  channelOutput = $("#channel-output", dialog),
  progressBar = $(".progress-bar", dialog),
  downloadButton = $("#download-button", dialog),
  eta = $(".eta", dialog),
  etaString = $("span.eta-string", eta);
  App.startDatepicker();
  if ($("#filter_vehicle_classes option:selected").length == 0) {
    $("#filter_vehicle_classes option:first").attr("selected", "selected");
  }
  progressBar.css("width", "0%");
  progressBar.html("");
  channelOutput.html("Idle");
  exportButton.removeClass("d-none");
  downloadButton.addClass("d-none");
  dialogTimestamp = Date.now();
  $("#export_dialog_timestamp", dialog).val(dialogTimestamp);
  eta.addClass("d-none");
  etaString.html("");
  exportButton.on("click", function(){
    downloadButton.data("empty", false);
    if (!subscription){
      subscription = App.consumer.subscriptions.create(
        { channel: "ExportTrainsChannel" },
        { received: receiveMessage }
      );
    }
    exportButton.addClass("d-none");
    channelOutput.html("Warte auf Background-Job…");
  });
}

function receiveMessage(jsonString) {
  let data = JSON.parse(jsonString);
  if (data.dialog_timestamp != dialogTimestamp){
    return;
  }
  if (data.message) {
    exportButton.addClass("d-none");
    channelOutput.html(data.message);
  }
  if (data.fraction) {
    progressBar.css("width", 100*data.fraction + "%");
    progressBar.html(format(data.fraction));
    progressBar.removeClass("progress-bar-animated");
    progressBar.removeClass("progress-bar-striped");
  }
  if (data.eta) {
    eta.removeClass("d-none");
    etaString.html(data.eta);
  }
  if (data.empty) {
    exportButton.removeClass("d-none");
    channelOutput.html(data.empty);
    downloadButton.data("empty", true);
  }
  if (data.no_estimate) {
    progressBar.css("width", "100%");
    progressBar.addClass("progress-bar-animated");
    progressBar.addClass("progress-bar-striped");
    progressBar.html("Dauer unbekannt…");    
  }
  if (data.result) {
    exportButton.addClass("d-none");
    eta.addClass("d-none");
    progressBar.css("width", "100%");
    progressBar.html("Fertig.");    
    channelOutput.html("Export komplett.");
    let downloadLink = $(`<a href="/trains/download_export_file?file_id=${data.result}" download></a>`)
      .appendTo("body");
    downloadLink[0].click();
    downloadLink.remove();
  }
}

function stopSubsription(){
  if (subscription){
    App.consumer.subscriptions.remove(subscription);
  }
  subscription = null;
}

function installExportDialogHandler(){
  $(document).on("shown.bs.modal", "#choose-export-range-modal, #cargo-train-packets-modal, #axles-counter-modal", initDialog);
  $(document).on("hide.bs.modal", "#choose-export-range-modal, #cargo-train-packets-modal #axles-counter-modal", stopSubsription);
}

postInit("trains#menu", installExportDialogHandler);