/* global $, App */

import {
  preInit,
  postInit
} from "./platin";

const startCountdown = function () {
  const span = $("span.cbt-countdown"),
    spanPb = $("span.cbt-countdown-pb"),
    start = App.testbetriebStart,
    startPb = App.probebetriebStart,
    endPb = App.probebetriebEnd,
    data = span.data();

  function countdown() {
    if (!data){
      return;
    }
    let secs = Math.floor((start - (new Date())) / 1000),
      days = Math.floor(secs / (24 * 60 * 60)),
      daysSuffix = (days == 1) ? data["templateFuture-2Sing"] : data["templateFuture-2"],
      hours = Math.floor((secs - days * 24 * 60 * 60) / (60 * 60)),
      minutes = Math.floor((secs - days * 24 * 60 * 60 - hours * 60 * 60) / (60)),
      minString = (minutes < 10) ? `0${minutes}` : minutes,
      hrString = (hours < 10) ? `0${hours}` : hours,
      daysPast = Math.floor(-secs / (24 * 60 * 60)),
      daysPastSuffix = (daysPast == 1) ? data["templatePast-2Sing"] : data["templatePast-2"],
      hoursPast = Math.floor((-secs - daysPast * 24 * 60 * 60) / (60 * 60)),
      futureHrSuffix = data["templateFuture-3"],
      pastHrSuffix = (hoursPast == 1) ? data["templatePast-3Sing"] : data["templatePast-3"],

      secsPb = Math.floor((startPb - (new Date())) / 1000),
      daysPb = Math.floor(secsPb / (24 * 60 * 60)),
      daysSuffixPb = (days == 1) ? data["templateFuture-2Sing"] : data["templateFuture-2"],
      hoursPb = Math.floor((secsPb - daysPb * 24 * 60 * 60) / (60 * 60)),
      minutesPb = Math.floor((secsPb - daysPb * 24 * 60 * 60 - hoursPb * 60 * 60) / (60)),
      minStringPb = (minutesPb < 10) ? `0${minutesPb}` : minutesPb,
      hrStringPb = (hoursPb < 10) ? `0${hoursPb}` : hoursPb,
      daysPastPb = Math.floor(-secsPb / (24 * 60 * 60)),
      daysPastSuffixPb = (daysPastPb == 1) ? data["templatePast-2Sing"] : data["templatePast-2"],
      hoursPastPb = Math.floor((-secsPb - daysPastPb * 24 * 60 * 60) / (60 * 60)),
      futureHrSuffixPb = data["templateFuture-3"],
      pastHrSuffixPb = (hoursPastPb == 1) ? data["templatePast-3Sing"] : data["templatePast-3"],

      secsComm = Math.floor((endPb - (new Date())) / 1000),
      daysComm = Math.floor(secsComm / (24 * 60 * 60)),
      daysSuffixComm = (days == 1) ? data["templateFuture-2Sing"] : data["templateFuture-2"],
      hoursComm = Math.floor((secsComm - daysComm * 24 * 60 * 60) / (60 * 60)),
      minutesComm = Math.floor((secsComm - daysComm * 24 * 60 * 60 - hoursComm * 60 * 60) / (60)),
      minStringComm = (minutesComm < 10) ? `0${minutesComm}` : minutesComm,
      hrStringComm = (hoursComm < 10) ? `0${hoursComm}` : hoursComm,
      // daysPastComm = Math.floor(-secsComm / (24 * 60 * 60)),
      // daysPastSuffixComm = (daysPastComm == 1) ? data["templatePast-2Sing"] : data["templatePast-2"],
      // hoursPastComm = Math.floor((-secsComm - daysPastComm * 24 * 60 * 60) / (60 * 60)),
      futureHrSuffixComm = data["templateFuture-3"];
      // pastHrSuffixComm = (hoursPastComm == 1) ? data["templatePast-3Sing"] : data["templatePast-3"];


    if (secsComm > 0) {

      if (secs > 0) {
        span.html(`${data["templateFuture-1"]}${days}${daysSuffix}${hrString}:${minString}${futureHrSuffix}`);
      } else if (secsPb > 0) {
        span.html(`${data["templatePast-1"]}${daysPast}${daysPastSuffix}${hoursPast}${pastHrSuffix}`);
        span.addClass("text-success");
      } else {
        span.html(`${data["templateTbDone"]}`);
      }

      if (secsPb > 0) {
        spanPb.html(`${spanPb.data("template-pb-1")}${daysPb}${daysSuffixPb}${hrStringPb}:${minStringPb}${futureHrSuffixPb}`);
      } else {
        let text = `${spanPb.data("template-pb-past-1")}${daysPastPb}${daysPastSuffixPb}${hoursPastPb}${pastHrSuffixPb}`;
        text += `<br/>${spanPb.data("template-pb-end-1")}${daysComm}${daysSuffixComm}${hrStringComm}:${minStringComm}${futureHrSuffixComm}`;
        spanPb.html(text);
        spanPb.addClass("text-success");
      }

    }  else {
      span.html("Test- und Probebetrieb sind beendet, der CBT ist im ersten kommerziellen Betriebsjahr.");
    }
  }

  // 2017-09-26 / Matthias Kummer
  // countdown on index page
  countdown();
  setInterval(countdown, 10000);
};

const loadPassengerStatus = function () {
  $("a[href='#passenger-status']").on("shown.bs.tab", function () {
    $("#passenger-status-output").html("Loading…");
    $.get("/admin/passenger_status", function (result) {
      $("#passenger-status-output").html(JSON.parse(result));
    });
  });
};

const handleNewsMessages = function () {
  const dialog = $("#show-news-message-dialog"),
    table = $("table#dt-news-messages");

  /* expects {id:, status_string:} */
  function updateRow(data) {
    console.log(data);
    $("tr[data-news-message-id='" + data.news_message_id + "'] td.status", table).html(data.status_string);
  }

  $("button.change-status", dialog).click(function () {
    var id = $(".news-message", dialog).data("user-news-message-id"),
      status = $(this).data("status");
    $.get("/news_messages/change_status", {
      user_news_message_id: id,
      status: status
    }, updateRow);
    return true;
  });
};

function prepareCovAlert() {
  $("#cov-alert .trix-content a[href*='preview']").attr("data-turbolinks", false);
}

postInit("home#index", startCountdown);
postInit("home#index", prepareCovAlert);
postInit("home#index", handleNewsMessages);
preInit("home#index", loadPassengerStatus);